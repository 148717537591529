import React from "react";
import {
	IgniteYourCareerContent,
	IgniteYourCareerCard,
} from "@components/Careers/IgniteYourCareer/IgniteYourCareer";
import IgniteYourCareer from "@components/Careers/IgniteYourCareer/IgniteYourCareer";
import { Media } from "@ryerson/frontend.layout";
import { ContentSection } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import { Icon } from "@ryerson/frontend.assets";
import styled from "@emotion/styled";
import { ContentfulCharacteristicsProperties, GradeTableItem } from "./Types/Types";

export type DataTableEntry = {
	grade: string;
	alloyingElement: string;
	characteristics: string;
};

export type StaticCharacteristicsContent = {
	gradeTableHeadings?: DataTableEntry;
	title1: string;
	title2: string;
	navLabel: string;
	desktopImage: string;
	mobileImage: string;
};

export type DynamicCharacteristicsContent = {
	characteristics: ContentfulCharacteristicsProperties[];
	blurb?: string | null;
	gradeTableItems?: GradeTableItem[] | null;
};

export type CharacteristicsContent = {
	characteristics: IgniteYourCareerContent;
	description: string;
	tableHeadings?: DataTableEntry;
	tableData?: DataTableEntry[];
};

export type CharacteristicsProps = {
	staticContent: StaticCharacteristicsContent;
	dynamicContent: DynamicCharacteristicsContent;
};

const TableRow = styled.div`
	display: block;
	width: 100%;
`;

const TableCell = styled.div`
	display: inline-block;
	vertical-align: top;
	padding: 20px;
	box-sizing: border-box;
`;

const Divider = styled.hr`
	display: block;
	margin: 0;
	opacity: 0.1;
	width: calc(100% - 60px);
	margin-left: 20px;
`;

const MobileIcon = styled.div`
	display: inline-block;
	width: 18px;
	height: 18px;
	vertical-align: top;
	margin-right: 13px;
`;

const MobileHeading = styled.div`
	display: inline-block;
	width: calc(100% - 31px);
	vertical-align: top;
	padding-right: 20px;
	box-sizing: border-box;
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 20px;
`;

const CharacteristicsComponent: React.FC<CharacteristicsProps> = ({
	staticContent,
	dynamicContent,
}) => {
	const { theme } = useTheme();
	let colorsSplit;
	let cards: IgniteYourCareerCard[] = [];
	for (let i = 0; i < dynamicContent.characteristics.length; i++) {
		cards.push({
			title: dynamicContent.characteristics[i].title,
			description: dynamicContent.characteristics[i].description.description,
			mobileImage: staticContent.mobileImage,
			desktopImage: staticContent.desktopImage,
		});
	}
	if (staticContent.gradeTableHeadings && dynamicContent.gradeTableItems) {
		colorsSplit = [
			{
				color: theme.colors.tertiary.background,
				percentage: "60%",
			},
			{
				color: theme.colors.secondary.background,
				percentage: "40%",
			},
		];
	} else {
		colorsSplit = [
			{
				color: theme.colors.tertiary.background,
				percentage: "100%",
			},
		];
	}

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<IgniteYourCareer
					content={{
						title1: staticContent.title1,
						title2: staticContent.title2,
						navLabel: staticContent.navLabel,
						cards: cards,
					}}
					type="tertiary"
					firstImageOnly={true}
					title1Left="-305px"
					title2Left="-145px"
					title1LeftSmaller="-130px"
					title2LeftSmaller="-35px"
					title1Parallax={["-85px", "-10px"]}
					title2Parallax={["-75px", "-145px"]}
					title1ParallaxSmaller={["-65px", "10px"]}
					title2ParallaxSmaller={["-35px", "-110px"]}
				/>

				<ContentSection
					type="split"
					vPadding="98px"
					split={{
						splitDirection: "vertical",
						splitColors: colorsSplit,
					}}
				>
					{dynamicContent.blurb && (
						<Typography
							variant="h4"
							css={css`
								letter-spacing: -1px;
								display: block;
								max-width: 760px;
								margin-bottom: 54px;
							`}
							type="tertiary"
							color={theme.colors.primary.lighterGray}
						>
							{dynamicContent.blurb}
						</Typography>
					)}

					{staticContent.gradeTableHeadings && dynamicContent.gradeTableItems && (
						<>
							<TableRow
								css={css`
									background-color: ${theme.colors.secondary.background};
									height: 61px;
									border-radius: 2px 2px 0 0;
								`}
							>
								<TableCell
									css={css`
										width: 174px;
									`}
								>
									<Typography
										variant="div"
										size="sm"
										weight="bold"
										color={theme.colors.primary.header}
									>
										{staticContent.gradeTableHeadings.grade}
									</Typography>
								</TableCell>
								<TableCell
									css={css`
										width: 396px;
									`}
								>
									<Typography
										variant="div"
										size="sm"
										weight="bold"
										color={theme.colors.primary.header}
									>
										{staticContent.gradeTableHeadings.alloyingElement}
									</Typography>
								</TableCell>
								<TableCell
									css={css`
										width: calc(100% - 570px);
									`}
								>
									<Typography
										variant="div"
										size="sm"
										weight="bold"
										color={theme.colors.primary.header}
									>
										{staticContent.gradeTableHeadings.characteristics}
									</Typography>
								</TableCell>
							</TableRow>
							{dynamicContent.gradeTableItems.map(
								(data: GradeTableItem, index: number) => {
									return (
										<TableRow
											css={css`
												background-color: ${theme.colors.primary
													.background};
												height: auto;
												${dynamicContent.gradeTableItems &&
												dynamicContent.gradeTableItems.length - 1 === index
													? "border-radius: 0 0 2px 2px"
													: "border-radius: 0 0 0 0"};
											`}
										>
											<TableCell
												css={css`
													width: 174px;
												`}
											>
												<Typography
													variant="div"
													size="md"
													color={theme.colors.primary.header}
												>
													{data.grade}
												</Typography>
											</TableCell>
											<TableCell
												css={css`
													width: 396px;
												`}
											>
												<Typography
													variant="div"
													size="md"
													color={theme.colors.primary.header}
												>
													{
														data.primaryAlloyingElements
															.primaryAlloyingElements
													}
												</Typography>
											</TableCell>
											<TableCell
												css={css`
													width: calc(100% - 570px);
													padding-right: 40px;
												`}
											>
												<Typography
													variant="div"
													size="md"
													color={theme.colors.primary.header}
												>
													{data.characteristics.characteristics}
												</Typography>
											</TableCell>
											<Divider
												css={css`
													color: ${theme.colors.primary.darkerGray};
												`}
											/>
										</TableRow>
									);
								}
							)}
						</>
					)}
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="primary" vPadding="40px" />
				<ContentSection type="tertiary" hPadding="20px" vPadding="0">
					<img
						css={css`
							width: calc(100% + 20px);
							margin-left: -20px;
							margin-bottom: 8px;
							margin-top: -40px;
						`}
						src={cards[0].mobileImage}
					/>
					{cards.map((card: IgniteYourCareerCard, index: number) => {
						return (
							<React.Fragment key={index}>
								<Spacer />
								<MobileIcon>
									<Icon color={theme.colors.tertiary.header} icon="check" />
								</MobileIcon>
								<MobileHeading>
									<Typography
										variant="h4"
										type="tertiary"
										weight="bolder"
										css={css`
											font-size: 24px;
											letter-spacing: -1px;
											line-height: 30px;
										`}
									>
										{card.title}
									</Typography>
								</MobileHeading>
								<Typography
									variant="div"
									size="md"
									type="tertiary"
									css={css`
										margin-bottom: 20px;
										margin-top: 14px;
									`}
								>
									{card.description}
								</Typography>
							</React.Fragment>
						);
					})}
					<Spacer />
					<Spacer />
				</ContentSection>
				{dynamicContent.blurb && (
					<ContentSection type="primary" vPadding="32px" hPadding="0px">
						<Typography
							variant="div"
							size="lg"
							type="primary"
							weight="bold"
							css={css`
								letter-spacing: -1px;
							`}
						>
							{dynamicContent.blurb}
						</Typography>
					</ContentSection>
				)}
				{staticContent.gradeTableHeadings && dynamicContent.gradeTableItems && (
					<>
						{dynamicContent.gradeTableItems.map(
							(data: GradeTableItem, index: number) => {
								return (
									<React.Fragment key={index}>
										<ContentSection
											type="secondary"
											vPadding="20px"
											hPadding="18px"
										>
											<Typography
												variant="div"
												size="md"
												weight="bold"
												color={theme.colors.primary.header}
												css={css`
													margin-bottom: 4px;
												`}
											>
												{staticContent.gradeTableHeadings
													? staticContent.gradeTableHeadings.grade
													: ""}
											</Typography>
											<Typography
												variant="div"
												size="md"
												type="secondary"
												css={css`
													margin-bottom: 18px;
												`}
											>
												{data.grade}
											</Typography>
											<Typography
												variant="div"
												size="md"
												weight="bold"
												color={theme.colors.primary.header}
												css={css`
													margin-bottom: 4px;
												`}
											>
												{staticContent.gradeTableHeadings
													? staticContent.gradeTableHeadings
															.alloyingElement
													: ""}
											</Typography>
											<Typography
												variant="div"
												size="md"
												type="secondary"
												css={css`
													margin-bottom: 18px;
												`}
											>
												{
													data.primaryAlloyingElements
														.primaryAlloyingElements
												}
											</Typography>
											<Typography
												variant="div"
												size="md"
												weight="bold"
												color={theme.colors.primary.header}
												css={css`
													margin-bottom: 4px;
												`}
											>
												{staticContent.gradeTableHeadings
													? staticContent.gradeTableHeadings
															.characteristics
													: ""}
											</Typography>
											<Typography
												variant="div"
												size="md"
												type="secondary"
												css={css`
													margin-bottom: 4px;
												`}
											>
												{data.characteristics.characteristics}
											</Typography>
										</ContentSection>
										<Spacer />
									</React.Fragment>
								);
							}
						)}
					</>
				)}
				<Spacer />
				<Spacer />
			</Media>
		</>
	);
};

export default CharacteristicsComponent;
